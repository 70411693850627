import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loaders: React.FunctionComponent = () => {
  return (
    <Box className={`app-loader d-flex justify-content-center align-items-center`} zIndex={9999999} component={"div"} bgcolor={"rgba(0, 0, 0, 0.3)"} data-testid="KenLoader">
      <CircularProgress sx={{ color: `#e05717 !important` }} />
    </Box>
  );
};

export default Loaders;
