import React from "react";
import { Route, Routes } from "react-router-dom";
import Themes from "./Helpers/Themes";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import Loaders from "./Components/Loader";
import { PrivateRoute } from "./Components/PrivateRoute";
import { PublicRoute } from "./Components/PublicRoute";
import { SnackbarProvider } from "notistack";

const DashBoard = React.lazy(() => import("./Application/DashBoard/index"));
const StartUps = React.lazy(() => import("./Application/Startup/index"));
const Login = React.lazy(() => import("./Application/Login/index"));

const App: React.FunctionComponent = () => {
  const query = new QueryClient();

  return (
    <QueryClientProvider client={query}>
      <SnackbarProvider autoHideDuration={5000}>
        <React.Suspense fallback={<Loaders />}>
          <Themes>
            <Routes>
              <Route
                path="/"
                index
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                path="/home"
                element={
                  <PrivateRoute>
                    <DashBoard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/startup-profile/:id"
                index
                element={
                  <PrivateRoute>
                    <StartUps />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Themes>
        </React.Suspense>
      </SnackbarProvider>
    </QueryClientProvider>
  );
};

export default App;
