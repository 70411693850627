import React from "react";
import createTheme from "@mui/system/createTheme";
import ThemeProvider from "@mui/styles/ThemeProvider";
import propType from "prop-types";
import { ITheme } from "../Interfaces/ITheme";
import { defaultColor, defaultConfig } from "../Themes/themeConfigLogin";

const Themes: React.FC<ITheme> = ({ children }) => {
  const fontFamily = "Red Hat Text";

  const customTheme = createTheme({
    typography: {
      allVariants: {
        fontFamily: [fontFamily, "sans-serif"].join(","),
        textTransform: "none",
        primaryTheme:  "#07588f",
        appTheme: `${defaultColor.color1}`,
        grey:  `${defaultColor.grey}`,
        dark: `${defaultColor.dark}`,
        light: `${defaultColor.light}`,
        tasksText: `${defaultColor.tasksText}`,
        profileMain: `${defaultColor.profileMain}`,
      },
    },
  });

  const defaultTheme = createTheme({
    typography: {
      allVariants: {
        fontFamily: [fontFamily, "sans-serif"].join(","),
        textTransform: "none",
      },
    },
  });
/*
  React.useEffect(()=>{
    //document.documentElement.style.setProperty('--appTheme', "#000000");
    //document.documentElement.style.setProperty('--light', "#000000");
    // document.documentElement.style.setProperty('--font-size-12', "20px");
  
    //console.log("changed the color from inside of themes.tsx");
    //console.log( document.documentElement.style.getPropertyValue('--appTheme'));
  }, []);
  */

  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
};


Themes.propTypes = {
  children: propType.node.isRequired,
};

export default Themes;
