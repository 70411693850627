/**
 * different font sizes used in repo
 */
export const defaultSize = {
  12: "12",
  14: "14",
  16: "16",
  18: "18",
  20: "20",
  22: "22",
  24: "24",
  32: "32",
}

/**
 * differnt colors
 */
export const defaultColor = {
  color1: "black",
  color2: "grey",
  color3: "white",
  color4: "#F4FAFF",
  color5: "#061938",
  color6: "#505F79",
  color7: "#07588f",
  color8: "#E4E4E4",
  color9: "#E0E0E0",
  light: "#ffff",
  dark: "#000000",
  grey: "rgba(0, 0, 0, 0.7)",
  tasksText: "#332620",
  profileMain: "#f8fbff",
  errorColor: "rgb(255, 0, 0)"
}

/**
 * differnt thickness of text
 */
export const defaultThickness = {
  bold: "bold",
  normal: "normal",
  thin: "thin"
}

/**
 * different images used in repo
 */
export const defaultBackGroundImages = {
  url1 : require("../Assets/Loginbackground.png"),
}

// config.js
export const loginConfig = {
  primaryColor: defaultColor.color5,
  secondaryColor: defaultColor.color6,
  backgroundImage: defaultBackGroundImages.url1,
  loginFormBgColor: "bg-blue",
  loginFormFieldFontColor: "font-grey",
  loginFormFieldFontColor2: defaultColor.color1,
  otpInputStyle: {
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    width: "40px",
    height: "40px",
    fontSize: "16px",
    margin: "0 8px",
    textAlign: "center",
  },
};

export const dashBoardConfig = {
  primaryFont12: "font-black-12-normal",
  primaryFont: "font-black-14-normal",
  primaryFont16: "font-black-16-normal",
  primaryHeadings: "font-black-18-bold",
  userHeadingBold: "font-black-24-bold",
  primaryExtraLargeBold: "font-black-32-bold",

  secondaryFont12: "font-grey-12-normal",
  secondaryFont: "font-grey-14-normal",
  secondaryFontGrey: "font-grey-18-normal",

  secondaryFontGreySmallBold: "font-grey-14-bold",
  secondaryFont14thin: "font-grey-14-thin",
  
  primaryFontBold: "font-primary-14-bold",
  primaryForntLargeBold: "font-primary-16-bold",
  primaryFont20thin: "font-primary-20-thin",
  primaryFontExLarge: "font-primary-20-bold",
  primaryFontExLargeBold: "font-primary-20-bold",

  borderStyleDefault: "2px solid #E4E4E4",
  borderStyle2Default: "1px solid #F4FAFF",

}

export const defaultConfig = {
  primaryFont: "font-black-14-bold",
  primaryFontLarge: "font-black-20-bold",
  secondaryFontSmall: "font-grey-12-bold",
  primaryColor: defaultColor.color7,
  whiteFonts: "font-white-16-bold",
  blackFonts: "font-black-16-bold",
  tertiaryColor: defaultColor.color4,
  color1: defaultColor.color1,
  color2: defaultColor.color2,
  color3: defaultColor.color3,
}